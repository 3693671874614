import { IRootState } from '@store/types';
import { combineReducers } from 'redux';

import { partnershipOnboardingReducer } from '@redux/partnership/onboarding/reducer';
import { commonReducer } from '@redux/common/reducer';

export default combineReducers<IRootState>({
    partnershipOnboarding: partnershipOnboardingReducer,
    common: commonReducer,
});
