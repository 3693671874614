/* eslint-disable func-names */
/* eslint-disable no-extend-native */
export default function init() {
    Promise.prototype.finally =
        Promise.prototype.finally ||
        function (callback) {
            return this.then(
                (value: any) =>
                    this.constructor.resolve(callback?.()).then(() => value),
                (reason: any) =>
                    this.constructor.resolve(callback?.()).then(() => {
                        throw reason;
                    })
            );
        };
}
