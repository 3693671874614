/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';
import initPolyfills from './poliyfills';

export function initGlobals() {
    if (typeof window !== 'undefined') {
        initPolyfills();
        window['r2d2'] = r2d2;
        window['track'] = track;
        window['cookie'] = cookie;
    }
}

function r2d2(in_: InR2D2) {
    const url = cookie('corp_session')
        ? `${
              process.env.NEXT_PUBLIC_CORP_PLATFORM_AUTH_URL || ''
          }/authorization/api/r2d2/`
        : `/api/r2d2/`;

    return axios.post(
        url,
        {
            app: 'NS_Container',
            ua: window?.navigator.userAgent,
            user: in_.user || cookie('user_id'),
            tracker: in_.tracker || cookie('trackerid'),
            ...in_,
            edata: {
                ...(in_.edata || {}),
                anonymousID: webengageAnonymousID(),
                ga_client_id: getGAClientID(),
                is_js: true,
                platform: window.innerWidth < 480 ? 'mweb' : 'web',
                web_engage: true,
            },
            odata: {
                ...(in_.odata || {}),
                url: window?.location.href,
            },
        },
        {
            withCredentials: true,
        }
    );
}

function track(in_: InGA) {
    // @ts-ignore
    typeof dataLayer !== 'undefined' && dataLayer.push(in_);
}

function getGAClientID() {
    try {
        return window['ga'].getAll()[0].get('clientId');
    } catch {
        return '';
    }
}

function webengageAnonymousID() {
    let aid = cookie('we_aid');
    if (aid !== undefined) return aid;
    try {
        aid = window.webengage.user.getAnonymousId();
        cookie('we_aid', aid);
    } catch {
        aid = '';
    }
    return aid;
}

function cookie(key: string, value?: string) {
    if (typeof document === 'undefined') return;
    if (typeof key === 'string' && typeof value === 'string') {
        document.cookie = `${key}=${value};path=/`;
        return value;
    }
    if (document.cookie.indexOf(`${key}=`) === -1) return;
    return document.cookie.split(`${key}=`)[1].split(';')[0];
}
