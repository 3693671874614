import { CommonActions } from './actionCreators';
import { Draft, produce } from 'immer';
import { CommonActionTypes } from './actionTypes';
import { ICommonState } from '@interfaces/common';

const commonState: ICommonState = {
    snackbarErrorMessage: {
        message: '',
        type: '',
    },
};

export const commonReducer = produce(
    (draft: Draft<ICommonState>, action: CommonActions): void => {
        switch (action.type) {
            case CommonActionTypes.UPDATE_SNACK_BAR_ERROR_MESSAGE:
                draft.snackbarErrorMessage = action.payload;
                break;
            default:
                draft;
        }
    },
    commonState
);

export const COMMON_REDUCER_KEY = 'common';
