import { AnyAction, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const reducer = (state: ReturnType<typeof rootReducer>, action: AnyAction) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        };
        return nextState;
    }
    return rootReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

export const makeStore = () => {
    const store = configureStore({
        reducer,
        middleware: [sagaMiddleware],
    });
    (store as any).sagaTask = sagaMiddleware.run(rootSaga);
    return store;
};

type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;

export const wrapper = createWrapper(makeStore, { debug: false });
