import type { Draft } from 'immer';
import produce from 'immer';
import { PartnershipOnboardingActionTypes } from './actionTypes';
import type { IPartnershipOnboardingState } from '@interfaces/partnership/onboarding';
import type { PartnershipOnboardingActions } from './actionCreators';
import { PartnershipOnboardingStates } from './states';

export const partnershipOnboardingReducer = produce(
    (
        draft: Draft<IPartnershipOnboardingState>,
        action: PartnershipOnboardingActions
    ): void => {
        switch (action.type) {
            case PartnershipOnboardingActionTypes.SET_ONBOARDING_DETAILS:
                draft.policyDetails = action.payload;
                break;
            case PartnershipOnboardingActionTypes.SET_ACTIVE_STAGE_SCREEN:
                draft.activeStageScreen = action.payload;
                break;
            default:
                draft;
        }
    },
    PartnershipOnboardingStates
);

export const PARTNERSHIP_ONBOARDING_REDUCER_KEY = 'partnershipOnboarding';
