/* eslint-disable import/no-extraneous-dependencies */
import App from 'next/app';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { createContext, useEffect, useRef } from 'react';
import {
    AckoMainTheme,
    ThemeProvider,
} from '@acko-tech/building-blocks.ui.theme';
import { identifyEvent } from '@acko-tech/building-blocks.ui.acko-analytics';
import Cookies from 'js-cookie';
import isMobile from 'ismobilejs';
import { ViewportProvider } from '@utils/responsive';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';
import { wrapper } from '@store/index';
import { initGlobals } from '@utils/globals';
import {
    ConsoleSpanExporter,
    SimpleSpanProcessor,
    WebTracerProvider,
} from '@opentelemetry/sdk-trace-web';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import '../styles/globals.css';
import Head from 'next/head';

export const PartnershipAppContext = createContext({
    reqCookie: '',
});

const growthbook = new GrowthBook({
    apiHost: 'https://growthbook-api.internal.ackodev.com',
    clientKey: 'sdk-DAddmX2TYErJcsz',
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
        // TODO: Use your real analytics tracking system
        console.log('Viewed Experiment', {
            experimentId: experiment.key,
            variationId: result.key,
        });
    },
});

function PartnershipApp(props: any) {
    const { Component, pageProps, isMobileDevice, query, host, reqCookie } =
        props;

    const queryClient = useRef(new QueryClient());
    const router = useRouter();

    useEffect(() => {
        try {
            if (typeof window !== 'undefined') {
                const w: any = window;
                w?.newrelic?.setErrorHandler((err: any) => {
                    if (err?.message?.toLowerCase()?.includes('script error')) {
                        return false;
                    }
                    return true;
                });
            }
        } catch {}
    }, []);

    useEffect(() => {
        initGlobals();

        /**
         * segment identify event
         */
        const trackerId = Cookies.get('trackerid');
        const userId = Cookies.get('user_id');

        identifyEvent({
            trackerId,
            userId,
        });
    }, []);

    useEffect(() => {
        growthbook.loadFeatures();
    }, [router?.pathname]);

    useEffect(() => {
        const provider = new WebTracerProvider();
        provider.addSpanProcessor(
            new SimpleSpanProcessor(new ConsoleSpanExporter())
        );

        provider.register({
            // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
            contextManager: new ZoneContextManager(),
        });

        // Registering instrumentations
        registerInstrumentations({
            instrumentations: [new DocumentLoadInstrumentation()],
        });
    }, []);

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
            </Head>
            <QueryClientProvider client={queryClient.current}>
                <GrowthBookProvider growthbook={growthbook}>
                    <Hydrate state={pageProps.dehydratedState}>
                        <ViewportProvider isMobileDevice={isMobileDevice}>
                            <ThemeProvider theme={AckoMainTheme}>
                                <Component
                                    {...pageProps}
                                    query={query}
                                    host={host}
                                    reqCookie={reqCookie}
                                />
                            </ThemeProvider>
                        </ViewportProvider>
                    </Hydrate>
                </GrowthBookProvider>
            </QueryClientProvider>
        </>
    );
}

PartnershipApp.getInitialProps = async (context: any) => {
    const appProps = await App.getInitialProps(context);
    const userAgent =
        typeof window === 'undefined'
            ? context?.ctx?.req?.headers['user-agent']
            : window?.navigator?.userAgent;

    const isMobileDevice = isMobile(userAgent).any;

    return {
        ...appProps,
        isMobileDevice,
        query: context?.ctx?.query,
        host: context?.ctx?.req?.headers?.host,
        reqCookie: context?.ctx?.req?.headers?.cookie || '',
    };
};

export default wrapper.withRedux(PartnershipApp);
