import { useState, useEffect, createContext, useContext } from 'react';

const viewportContext = createContext({ isMobile: true });

const ViewportProvider = ({ children, isMobileDevice }) => {
    const [isMobile, setIsMobile] = useState(isMobileDevice);

    useEffect(() => {
        // Create a condition that targets viewports at least 768px wide
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        function handleWindowResize(e) {
            // Check if the media query is true
            if (e.matches) {
                // Then log the following message to the console
                // console.log("Media Query Matched!");
                setIsMobile(true);
            } else {
                // console.log("Media Query Not Matched!");
                setIsMobile(false);
            }
        }

        // Register event listener
        mediaQuery.addListener(handleWindowResize);

        // Initial check
        handleWindowResize(mediaQuery);

        return () => mediaQuery.removeListener(handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ isMobile }}>
            {children}
        </viewportContext.Provider>
    );
};

const useViewport = () => {
    const { isMobile } = useContext(viewportContext);
    return { isMobile };
};

export { useViewport, ViewportProvider };
