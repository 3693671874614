import { IPartnershipOnboardingState } from '@interfaces/partnership/onboarding';
import { PartnershipOnboardingStages } from '@utils/constants';

export const PartnershipOnboardingStates: IPartnershipOnboardingState = {
    policyDetails: {
        user: {
            name: '',
            phone: '',
            email: '',
            pincode: '',
            user_profile: {
                whatsapp_optin: true,
            },
        },
        crosssell_details: {
            car: {
                is_existing_customer: false,
                registration_number: '',
                onboarding_shown: false,
                status: 'success',
            },
        },
        is_activated: false,
        policy_id: '',
        lob: '',
        policy_url: '',
        is_authenticated: false,
        plan: {
            id: '',
            category: '',
        },
    },
    activeStageScreen: PartnershipOnboardingStages.Welcome,
};
